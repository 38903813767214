/* eslint-disable import/prefer-default-export */
export const isBackgroundWhite = (cornerPixels: Uint8ClampedArray[]): boolean =>
  cornerPixels.filter((rgba) => rgba[0] === 255 && rgba[1] === 255 && rgba[2] === 255).length >= 3;

export const isBackgroundTransparent = (cornerPixels: Uint8ClampedArray[]): boolean =>
  // check if the alpha value is 0, means it's transparent
  cornerPixels.some((rgba) => rgba[3] === 0);

export const isBackgroundWhiteOrTransparent = (imageUrl: string): Promise<boolean> =>
  new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This is important for CORS
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        resolve(false);
        return;
      }
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      try {
        const cornerPixels = [
          context.getImageData(0, 0, 1, 1).data, // Top-left
          context.getImageData(img.width - 1, 0, 1, 1).data, // Top-right
          context.getImageData(0, img.height - 1, 1, 1).data, // Bottom-left
          context.getImageData(img.width - 1, img.height - 1, 1, 1).data, // Bottom-right
        ];
        const backgroundIsWhite = isBackgroundWhite(cornerPixels);
        const backgroundIsTransparent = isBackgroundTransparent(cornerPixels);
        resolve(backgroundIsWhite || backgroundIsTransparent);
      } catch (error) {
        console.error('Error accessing image data:', error);
      }
    };

    img.src = imageUrl;
  });
